import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Downloads from '../components/resources/Downloads';
import Blog from '../components/home/Blog';
import CtaNewsletter from '../components/CtaNewsletter';
import '../css/resources/resourcespage.scss';

export default function ResourcesPage({ data, location }) {
  const { title, heroText, heroImg } = data.sanityResourcespage.heroSection;
  const { downloads } = data.sanityResourcespage;
  const { seo } = data.sanityResourcespage;

  return (
    <>
      <SEO
        title={seo.title && seo.title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />

      <Layout>
        <Hero
          tag="Resources"
          title={title}
          para={heroText}
          heroImg={heroImg.asset.url}
        />
        <Downloads data={downloads} />
        <Blog />
        <CtaNewsletter />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityResourcespage {
      heroSection {
        title
        heroText
        heroImg {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      downloads {
        heading
        allFiles {
          description
          singleFile {
            asset {
              url
            }
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
