import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useForm } from '@formspree/react';

export default function CtaNewsletter() {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        ctaNewsletter {
          title
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const { ctaNewsletter } = data.sanitySiteSettings;

  const [userEmail, setUserEmail] = useState('');
  const [state, handleSubmit] = useForm('xnqwedlv', {
    data: {
      subject: `Newsletter subscription from ${userEmail}`,
    },
  });

  return (
    <>
      <div className="cta-box cta-newsletter">
        <div className="wrapper">
          <div className="cta-content">
            <div className="content">
              {state.succeeded ? (
                <h2 className="success-title">
                  Yay! You are subscribed to our newsletter 🎉
                </h2>
              ) : (
                <>
                  <h2>{ctaNewsletter.title}</h2>
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="newsletter-subscribe">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your email"
                        onChange={(e) => setUserEmail(e.target.value)}
                        required
                      />
                      <button type="submit" disabled={state.submitting}>
                        Subscribe
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>

            <div className="img">
              <GatsbyImage
                image={ctaNewsletter.icon.asset.gatsbyImageData}
                alt="Newsletter"
                objectFit="contain"
                className="invert-color"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
