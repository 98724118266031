import React from 'react';

export default function IconDownload() {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="#09051A"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.25 22.1875C11.125 22.1875 11.0125 22.1625 10.8875 22.1125C10.7165 22.0426 10.5703 21.9231 10.4679 21.7694C10.3654 21.6156 10.3113 21.4347 10.3125 21.25V13.75C10.3125 13.2375 10.7375 12.8125 11.25 12.8125C11.7625 12.8125 12.1875 13.2375 12.1875 13.75V18.9875L13.0875 18.0875C13.45 17.725 14.05 17.725 14.4125 18.0875C14.775 18.45 14.775 19.05 14.4125 19.4125L11.9125 21.9125C11.7375 22.0875 11.4875 22.1875 11.25 22.1875Z" />
        <path d="M11.2501 22.1875C11.0126 22.1875 10.7751 22.1 10.5876 21.9125L8.08756 19.4125C7.91321 19.2361 7.81543 18.998 7.81543 18.75C7.81543 18.502 7.91321 18.2639 8.08756 18.0875C8.45006 17.725 9.05006 17.725 9.41256 18.0875L11.9126 20.5875C12.2751 20.95 12.2751 21.55 11.9126 21.9125C11.7251 22.1 11.4876 22.1875 11.2501 22.1875Z" />
        <path d="M18.75 28.4375H11.25C4.4625 28.4375 1.5625 25.5375 1.5625 18.75V11.25C1.5625 4.4625 4.4625 1.5625 11.25 1.5625H17.5C18.0125 1.5625 18.4375 1.9875 18.4375 2.5C18.4375 3.0125 18.0125 3.4375 17.5 3.4375H11.25C5.4875 3.4375 3.4375 5.4875 3.4375 11.25V18.75C3.4375 24.5125 5.4875 26.5625 11.25 26.5625H18.75C24.5125 26.5625 26.5625 24.5125 26.5625 18.75V12.5C26.5625 11.9875 26.9875 11.5625 27.5 11.5625C28.0125 11.5625 28.4375 11.9875 28.4375 12.5V18.75C28.4375 25.5375 25.5375 28.4375 18.75 28.4375Z" />
        <path d="M27.5 13.4375H22.5C18.225 13.4375 16.5625 11.775 16.5625 7.5V2.5C16.5625 2.125 16.7875 1.775 17.1375 1.6375C17.4875 1.4875 17.8875 1.575 18.1625 1.8375L28.1625 11.8375C28.3172 11.8369 28.4696 11.8745 28.6063 11.947C28.7429 12.0195 28.8596 12.1246 28.9458 12.2531C29.032 12.3815 29.0851 12.5293 29.1005 12.6832C29.1158 12.8371 29.0928 12.9925 29.0337 13.1354C28.9745 13.2783 28.8809 13.4044 28.7612 13.5024C28.6416 13.6005 28.4995 13.6674 28.3478 13.6973C28.196 13.7272 28.0392 13.7191 27.8913 13.6738C27.7434 13.6285 27.609 13.5473 27.5 13.4375ZM18.4375 4.7625V7.5C18.4375 10.725 19.275 11.5625 22.5 11.5625H25.2375L18.4375 4.7625Z" />
      </svg>
    </>
  );
}
