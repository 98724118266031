import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function BlogCard({ href, title, author, date, image, alt }) {
  return (
    <>
      <Link to={`/blog/${href}`} className="blog-card">
        <div className="img">
          <GatsbyImage image={image} alt={alt} className="thumbnail" />
        </div>
        <div className="content">
          <h3>{title}</h3>
          <div className="info">
            <p className="mono">by {author || 'Ninedots'}</p>
            {date && <p className="mono">{date}</p>}
          </div>
        </div>
      </Link>
    </>
  );
}
