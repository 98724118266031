import React from 'react';
import IconDownload from '../../icons/IconDownload';

export default function Downloads({ data }) {
  return (
    <>
      <section className="download-sec">
        <div className="wrapper centered">
          <h2>{data.heading}</h2>

          <div className="all-downloads">
            {data.allFiles.map((file, i) => (
              <a
                href={file.singleFile.asset.url}
                download
                title="Download now"
                target="_blank"
                key={i}
                className="file-download"
                rel="noreferrer"
              >
                <div className="img-icon">
                  <IconDownload />
                </div>
                <h3>{file.description}</h3>
              </a>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
