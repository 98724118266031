import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import BlogCard from '../BlogCard';

export default function Blog({ title, para }) {
  const data = useStaticQuery(graphql`
    {
      allSanityBlogs(sort: { fields: date, order: DESC }, limit: 3) {
        nodes {
          title
          slug {
            current
          }
          thumbnail {
            alt
            asset {
              gatsbyImageData
            }
          }
          author
          date(formatString: "MMM D, YYYY")
        }
      }
    }
  `);
  const allBlogs = data.allSanityBlogs.nodes;

  return (
    <>
      <div className="from-our-blog">
        <div className="wrapper centered">
          <div className="heading">
            <h2>{title}</h2>
            {para && <p className="mono">{para}</p>}
          </div>

          <div className="all-blogs">
            {allBlogs.map((blog, i) => (
              <BlogCard
                href={blog.slug.current}
                title={blog.title}
                author={blog.author}
                image={blog.thumbnail.asset.gatsbyImageData}
                alt={blog.thumbnail.alt}
                key={i}
              />
            ))}
          </div>

          <div className="cta">
            <Link to="/blog" className="button outline">
              More articles
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
